@import '../../../styles/customMediaQueries.css';

.listingNavigation {
  display: flex;
  gap: 1rem;
  overflow-x: auto;
  position: sticky;
  top: 71px;
  background: #fff;
  z-index: 10;
  border-bottom: 1px solid #ddd;

  @media (max-width: 768px) {
    padding: 12px;
    gap: 10px;
    top: 61px;
    margin: 0;
  }

  & li {
    min-width: fit-content;

    & a {
      color: #9aa0a6;
      font-size: 20px;
      font-weight: 400;
      line-height: 1.5;
      text-decoration: none;
      padding-bottom: 10px;
      display: block;

      @media (max-width: 768px) {
        font-size: 16px;
      }
    }
  }
}

.tagLinesName {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  word-break: break-word;

  & li {
    word-break: break-word;
  }
}

.subInformationB {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }

  & h4 {
    font-weight: 700;
    font-weight: 500;
    line-height: 1.2;
    color: #9aa0a6;
    font-size: 16px;
    margin-top: 0;
  }

  & .specialitiesDeatail {
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    font-size: 16px;
  }
}

.specialitiesBox {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  @media (max-width: 768px) {
    overflow-x: auto;
  }
}

.specialitiesCard {
  font-size: 14px;
  padding: 1px 9px;
  border-radius: 59px;
  background-color: #f5f8fa;
  border: 0.5px solid #cccccc;
  color: #363e46;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
}

.serviceCard {
  gap: 20px;
  display: flex;
  padding: 24px 24px 48px 24px;
  margin: 1rem 0;
  border-radius: 5px;
  align-items: center;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.125);

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }

  & .titleAmount {
    font-weight: 600;
    line-height: 1.2;
    color: #000;
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 8px;
  }

  & .descriptionText {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
  }
}

.orderBtn {
  padding: 10px;
  width: 170px;
}

.imageSlider {
  display: flex;
  overflow-x: auto;
  width: 100%;

  @media (max-width: 560px) {
    /* flex-wrap: wrap; */
    gap: 10px;
  }

  & img {
    width: 220px;
    display: inline-grid;
    margin: 0 15px 0 0;

    @media (max-width: 560px) {
      width: 85%;
    }
  }
}

.orderFormBoxA {
  /* display: flex;
  gap: 1rem;
  flex-direction: column; */

  @media (max-width: 768px) {
    flex-direction: column;
  }

  & .inputBox {
    margin-bottom: 20px;

    & label {
      margin-left: 0;
    }
  }
}

.checkboxServices {
  /* margin-top: 15px; */
}

.descriptionInput {
  margin-top: 15px;

  & textarea {
    &::placeholder {
      font-size: 12px;
    }
  }
}

.addImageWrapper {
  height: 100px;
  width: 100px;
}

.addImageInput {
  display: none;
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  padding: 5px;
  /* Colors */
  background-color: var(--colorWhite);

  @media (--viewportLarge) {
    background-color: var(--colorGrey50);
  }

  border-style: dashed;
  border-color: var(--colorGrey100);
  border-width: 2px;
  border-radius: 2px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--colorGrey300);
  }
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;

  & span {
    font-size: 10px;
  }
}

.imageTypes {
  color: var(--colorGrey300);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.addImageSection {
  display: flex;
  margin-block: 1rem;
  gap: 1rem;

  & h6 {
    margin: 0;
    padding: 0;
  }

  & .modalImageSection {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(4, 1fr);

    @media (max-width: 560px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.orderAddedImage {
  position: relative;
  height: 100px;
  width: 100px;
  border-radius: 2px;

  & img {
    width: 100%;
    border-radius: 2px;
    height: 100%;
    object-fit: cover;
  }
}

.closeIcon {
  position: absolute;
  right: 0;
  height: 20px;
  width: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #999696a1;
  border: none;

  & svg {
    stroke: #02070c;
  }
}

.headingBox {
  & h6 {
    color: #9aa0a6;
    font-size: 15px;
    margin-bottom: 0.25rem;
    margin-top: 0;
    font-weight: 400;
    padding: 0;
    text-transform: capitalize;
  }

  & h4 {
    font-size: 30px;
    line-height: 1.23;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }
}

.serviceBox {
  @media (max-width: 768px) {
    padding: 0 24px;
  }

  & .serviceTitle {
    font-weight: 500;
    line-height: 1.2;
    color: var(--colorGrey700);
    font-size: 20px;
  }
}

.meetBox {
  & .meetMeTitle {
    font-weight: 500;
    line-height: 1.2;
    color: var(--colorGrey700);
    font-size: 20px;
  }
}

.upsell {
  margin-top: 1rem;
}

.selectedTab {
  border-bottom: 3px solid #ff6900 !important;
  text-decoration: none;
  color: #000 !important;
}

.tab {
  color: #000;
  font-size: 1.2rem;
  border-bottom: 3px solid transparent;
}

.tab:hover {
  border-bottom: 3px solid #ff6900;
  text-decoration: none;
  color: #000;
}

.refundWrapper {
  @media (max-width: 768px) {
    padding: 0 24px 40px 24px;
  }
}

.refundHeading {
  font-size: 20px;
  color: #000;
  font-weight: 600;
  margin: 10px 0 16px 0;
}

.refundDetails {
  color: #000;
  font-weight: 400;
  line-height: 24px;
  font-size: 16px;
  margin: 0;
}

.meetSection {
  @media (max-width: 768px) {
    padding: 0 24px;
  }
}

.meetHeading {
  font-size: 20px;
  color: #000;
  margin-top: 10px;
  font-weight: 600;
  margin: 0 0 16px 0;
}

.sectionHeading {
  & span {
    font-size: 20px;
    color: #000;
    margin-top: 10px;
    font-weight: 600;
    margin: 0 0 16px 0;
  }
}

.serviceHeading {
  font-size: 20px;
  color: #000;
  margin-top: 10px;
  font-weight: 600;
}

.listingHeader {
  @media (max-width: 768px) {
    padding: 0 20px;
  }

  & h4 {
    font-size: 30px;
    color: #000;
    font-weight: 400;
    margin: 0;
  }

  & h6 {
    color: #9aa0a6;
    font-size: 14px;
    margin: 0;
    padding: 0;
    font-weight: 400;
    margin-bottom: 8px;
  }
}

.saveMsgError {
  color: red;
  font-size: 14px;
}

.updateMessage {
  & label {
    & span {
      font-size: 13px;
    }
  }
}

.informationContainer {
  & p {
    font-size: 14px;
    line-height: 24px;
    color: #6c7191 !important;
  }

  @media (max-width: 768px) {
    /* padding: 0 24px; */
  }
}

.title {
  font-size: 24px;
  line-height: 34px;
}

.answer {
  font-size: 14px;
  line-height: 24px;
  color: #6c7191 !important;
}

.faqContent {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 0px;
  row-gap: 0px;

  & .faqText {
    position: relative;

    /* &:last-child{
       & svg{
          top: 34px;
          @media (max-width: 767px) {
            top: 28px;
          }
        }
      } */
    & svg {
      position: absolute;
      top: 23px;
      right: 21px;

      @media (max-width: 767px) {
        top: 28px;
        right: 15px;
      }
    }
  }

  & :global(.is-open) {
    position: relative;

    &:after {
      position: absolute;
      content: '';
      height: 24px;
      width: 24px;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 12H19' stroke='%233D70A1' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      right: 12px;
    }
  }

  & :global(.is-closed) {
    position: relative;

    &:after {
      position: absolute;
      content: '';
      height: 24px;
      width: 24px;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 5V19' stroke='%23949494' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5 12H19' stroke='%23949494' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      right: 12px;
    }
  }

  & :global(.Collapsible__trigger) {
    /* composes: h1 from global; */
    display: block;
    margin-bottom: 0px;
    padding: 10px 64px 0px 0;
    color: var(--black-shade-text-black, #272727);
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    /* 233.333% */
    height: auto;
    display: flex;
    align-items: center;
    cursor: pointer;

    @media (max-width: 1300px) {
      padding: 15px 48px 0px 18px;
    }

    @media (max-width: 767px) {
      margin-bottom: 12px;
      padding: 15px 44px 0px 12px;
      height: 54px;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
    }
  }

  & :global(.Collapsible) {
    height: 100%;
  }

  & :global(.Collapsible__contentInner) {
    margin: 0 15px;
    padding-bottom: 16px;
    color: var(--black-shade-para-text, #949494);
    /* font-family: Nunito; */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-bottom: 1px solid #dfdfdf;
    padding-top: 10px;

    word-break: break-word;

    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 20px;
    }
  }
}